<template>
    <div :class="systemType == 2 && childSystem == 1 ? 'content_one' : 'content'" id="systemName0" >
        <div class="title">
            <p>悠速外卖跑腿系统</p>
            <p>专注本地生活平台打造</p>
        </div>
        <div class="systemName">
            <div style="  cursor: pointer;" :class="systemType == index ? 'name' : ''"
                v-for="(item, index) in systemName" :key="index" @click="chooseSystem(index)" @mouseenter="swiperEnter"
                @mouseleave="swiperOut">
                <span>{{ item }}</span>
            </div>
        </div>
        <div class="system" :style="{ 'width': systemType == 2 && childSystem == 0 ? '1100px' : '800px' }" >
            <div>
                <div class="name" v-for="(item, index) in system" :key="index" @click="chooseType(index)"
                    @mouseenter="swiperEnter" @mouseleave="swiperOut" style="cursor: pointer;">
                    <div :class="childSystem == index ? 'round_two' : 'round_one'"
                        :style="{ top: `${index * 80 + 10}px` }">
                    </div>
                    <div :class="childSystem == index ? 'systemType_one' : 'systemType'"><span>{{ item }}</span></div>
                </div>
            </div>
            <div class="containt_one" v-for="(val) in containt" :key="val.id" >
                <h2> {{ val.title }}</h2>
                <p>{{ val.content }}</p>
                <div style="display: flex;flex-wrap: wrap;width: 280px;margin-top: 22px;">
                    <div v-for="(item, num) in val.type" :key="num" :class="'image' + num" style="margin-bottom: 23px;">
                        <div class="image">
                            <img :src="item.img" alt="" height="40px" width="40px">
                            <p style="font-size: 9px;">{{ item.name }}</p>
                        </div>
                    </div>
                </div>
                <button class="butn1" @click="experience()">立即体验</button>
                <!-- <button class="butn2">查看更多</button> -->
            </div>
            <div v-for="(val, index) in containt" :key="index">
                <img :src="val.image" alt="" height="335px">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "System",
    data() {
        return {
            timer: null,
            systemName: ["同城生活系统", "校园跑腿系统", "多平台运营系统"],
            nameStyle: {
                color: '#FD6A52',
            },
            system: [],
            system_0: ["本地跑腿系统", "同城外卖系统", "同城生活服务系统"],
            system_1: ["校园跑腿调度系统", "校园外卖平台系统"],
            system_2: ["多站点加盟平台", "多渠道运营平台"],
            containt: [],
            containt_0: [{
                id: 1,
                title: '本地跑腿系统',
                content: "适用于打造本地跑腿配送平台，支持多元化服务场景自定义设置，搭建万能跑腿配送服务平台，全面赋能本地配送创业者。",
                type: [
                    {
                        img: require("../../static/img/home/icon1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon3.png"),
                        name: "餐饮配送",
                    },
                    {
                        img: require("../../static/img/home/icon4.png"),
                        name: "货运搬家",
                    },
                    {
                        img: require("../../static/img/home/icon5.png"),
                        name: "代驾服务",
                    },
                ],
                image: require("../../static/img/home/phone1.png"),
            },
            ],
            containt_1: [{
                id: 2,
                title: '同城外卖系统',
                content: "对标美团平台，融合同城生活多行业，覆盖本地全业态商圈，为创业者打造自主品牌的本地O2O生活平台。",
                type: [
                    {
                        img: require("../../static/img/home/icon2_1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon2_2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon2_3.png"),
                        name: "餐饮配送",
                    },
                    {
                        img: require("../../static/img/home/icon2_4.png"),
                        name: "货运搬家",
                    },
                    {
                        img: require("../../static/img/home/icon2_5.png"),
                        name: "代驾服务",
                    },
                ],
                image: require("../../static/img/home/phone2.png"),
            },
            ],
            containt_2: [{
                id: 3,
                title: '同城生活服务系统',
                content: "对标美团平台，融合同城包含跑腿代办、餐饮、零售、生鲜多行业支持，从订餐下单，在线结算，到完成配送，提供本地生活流程提供专业解决方案。生活多行业，覆盖本地全业态商圈，为创业者打造自主品牌的本地O2O生活平台。",
                type: [
                    {
                        img: require("../../static/img/home/icon3.1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon3.2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon3.3.png"),
                        name: "餐饮配送",
                    },
                    {
                        img: require("../../static/img/home/icon3.4.png"),
                        name: "货运搬家",
                    },
                    {
                        img: require("../../static/img/home/icon3.5.png"),
                        name: "代驾服务",
                    },
                ],
                image: require("../../static/img/home/phone3.png"),
            },
            ],
            containt_3: [{
                id: 4,
                title: '校园跑腿系统',
                content: "针对多元的校园跑腿场景，与校园巨大的餐饮配送需求，通过对接美团饿了么订单接口，结合智能调度、校园中转、集中配送等模式提高校园效率。",
                type: [
                    {
                        img: require("../../static/img/home/icon1_1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon1_2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon1_3.png"),
                        name: "餐饮配送",
                    },
                    {
                        img: require("../../static/img/home/icon1_4.png"),
                        name: "货运搬家",
                    },
                    {
                        img: require("../../static/img/home/icon1_5.png"),
                        name: "代驾服务",
                    },
                ],
                image: require("../../static/img/home/phone1_1.png"),
            },
            ],
            containt_4: [{
                id: 5,
                title: '校园外卖平台系统',
                content: "打造学校移动掌上生活圈，支持学生创业者整合校内外餐饮美食商家，给学生提供线上点餐、外卖配送全套生活服务，打造独立品牌的智慧校园平台。",
                type: [
                    {
                        img: require("../../static/img/home/icon4_1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon4_2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon4_3.png"),
                        name: "餐饮配送",
                    },
                    {
                        img: require("../../static/img/home/icon4_4.png"),
                        name: "货运搬家",
                    },

                ],
                image: require("../../static/img/home/phone4.png"),
            },
            ],
            containt_5: [{
                id: 6,
                title: '多站点加盟平台',
                content: "支持同一平台小程序下，分区域/分站点独立运营，站点自主设置收款账户，独立管理商家、骑手，用户自由选择站点下单",
                type: [
                    {
                        img: require("../../static/img/home/icon5_1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon5_2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon5_3.png"),
                        name: "餐饮配送",
                    },

                ],
                image: require("../../static/img/home/phone5.png"),
            },
            ],
            containt_6: [{
                id: 7,
                title: '多渠道运营平台',
                content: "微信小程序多端合一，内容设置与信息数据互动，精准触达多平台消费用户，覆盖全域网络。",
                type: [
                    {
                        img: require("../../static/img/home/icon6_1.png"),
                        name: "家政上门",
                    },
                    {
                        img: require("../../static/img/home/icon6_2.png"),
                        name: "跑腿帮买",
                    },
                    {
                        img: require("../../static/img/home/icon6_3.png"),
                        name: "餐饮配送",
                    },

                ],
                // image: require("../../static/img/home/phone6.png"),
            },
            ],
            systemType: 0,
            childSystem: 0,
        };
    },
    beforeMount() {
        this.containt = [...this.containt_0]
        this.system = [...this.system_0]

    },
    watch: {
        systemType() {
            this.chooseSystem(this.systemType)
        },
        '$route.query'(val) {
            this.$nextTick(() => { this.toLocal() })
            clearInterval(this.timer);
            this.systemType = 0
            this.childSystem = val.toId
        }
    },
    created() {
        this.timer = setInterval(() => {
            if (this.systemType < 2) {
                this.systemType++
            } else {
                this.systemType = 0
            }

        }, 1500)
    },
    mounted() {
        let _this = this;
        _this.$nextTick(function () {
            window.addEventListener('scroll', _this.handleScroll)
        })
        if (this.$route.query.toId) {
            this.$nextTick(() => { this.toLocal() })
            clearInterval(this.timer);
            this.systemType = 0
            this.childSystem = this.$route.query.toId
        }
    },
    destroyed() {
        localStorage.setItem('toId', '');
        clearInterval(this.timer);
        window.removeEventListener('scroll', this.handleScrollbox, true)
    },
    methods: {
        experience() {
            document.documentElement.scrollTop = 0;
            this.$router.push('/ap')
        },
        swiperOut() {
            this.timer = setInterval(() => {
                if (this.systemType < 2) {
                    this.systemType++
                } else {
                    this.systemType = 0
                }

            }, 1500)
        },
        swiperEnter() {
            clearInterval(this.timer);
        },
        toLocal() {
            //查找存储的锚点id
            let Id = localStorage.getItem('toId');
            let toElement = document.getElementById(Id);
            //锚点存在跳转
            if (Id) {
                toElement.scrollIntoView()
            }
        },
        chooseSystem(index) {
            this.systemType = index
            switch (index) {
                case 0:
                    this.system.length = 0
                    this.system = [...this.system_0]
                    this.containt = [...this.containt_0]
                    this.childSystem = 0
                    break;
                case 1:
                    this.system.length = 0
                    this.system = [...this.system_1]
                    this.containt = [...this.containt_3]
                    this.childSystem = 0
                    break;

                case 2:
                    this.system.length = 0
                    this.system = [...this.system_2]
                    this.containt = [...this.containt_5]
                    this.childSystem = 0
                    break;
                default:
                    break;
            }
        },
        chooseType(index) {
            this.childSystem = index
            switch (this.systemType) {
                case 0:
                    switch (index) {
                        case 0:
                            this.containt.length = 0
                            this.containt = [...this.containt_0]
                            break;
                        case 1:
                            this.containt.length = 0
                            this.containt = [...this.containt_1]
                            break;
                        case 2:
                            this.containt.length = 0
                            this.containt = [...this.containt_2]
                            break;
                        default:
                            break;
                    }
                    break;
                case 1:
                    switch (index) {
                        case 0:
                            this.containt.length = 0
                            this.containt = [...this.containt_3]
                            break;
                        case 1:
                            this.childSystem = index
                            this.containt.length = 0
                            this.containt = [...this.containt_4]
                            break;

                        default:
                            break;
                    }
                    break;
                case 2:
                    switch (index) {
                        case 0:
                            this.containt.length = 0
                            this.containt = [...this.containt_5]
                            break;
                        case 1:
                            this.containt.length = 0
                            this.containt = [...this.containt_6]
                            break;

                        default:
                            break;
                    }
                    break;

                default:
                    break;
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFCFB;
    padding-bottom: 100px;
    // background-repeat: no-repeat;
    background-size: 1140px 625px;
    background-position: 360px -5px;
    background-repeat: no-repeat;
}

.content_one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFCFB;
    padding-bottom: 100px;
    // background-repeat: no-repeat;
    background-image: url('../../static/img/home/phone6.png');
    background-size: 1140px 625px;
    background-position: 480px -5px;
    background-repeat: no-repeat;
}

.title {
    font-size: 23px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2B2422;
    line-height: 31px;
    padding-top: 49px;
    margin-bottom: 30px;
}

.systemName {
    width: 700px;
    height: 50px;
    background: #EFEFEF;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    >div {
        width: 200px;

        span {
            line-height: 35px;
            font-weight: bold;
            font-family: Microsoft YaHei;
            // font-size: 11px;
        }
    }

    .name {
        height: 35px;
        background: #FFFFFF;
        border-radius: 16px;
        color: #FD6A52;
    }
}

.system {
    width: 800px;
    position: relative;
    border-left: 1px solid #CCCCCC;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    .name {
        text-align: left;

        .round_one {
            width: 10px;
            height: 10px;
            background: #FFFCFB;
            border: 1px solid #CCCCCC;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: -5px;
        }

        .round_two {
            width: 10px;
            height: 10px;
            // border: 1px solid #CCCCCC;
            border-radius: 50%;
            position: absolute;
            top: 90px;
            left: -5px;
            background: #FD6A52;
            border-radius: 50%;
        }



        .systemType {
            width: 120px;
            height: 30px;
            padding-left: 10px;
            border-radius: 0px 5px 5px 0px;
            // border: 1px solid #FD6A52;
            font-size: 13px;
            text-align: left;
            margin-bottom: 50px;
            line-height: 30px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #333333;
        }

        .systemType_one {
            width: 120px;
            height: 30px;
            padding-left: 10px;
            border-radius: 0px 5px 5px 0px;
            // border: 1px solid #FD6A52;
            font-size: 13px;
            text-align: left;
            margin-bottom: 50px;
            line-height: 30px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #FD6A52;
            border: 1px solid #FD6A52;
            border-left-color: transparent;
            border-radius: 0px 5px 5px 0px;
        }
    }

    .containt_one {
        text-align: left;
        width: 300px;
        font-size: 13px;

        h2 {
            padding-bottom: 30px;
        }

        .image {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 60px;
        }

        .image0 {
            margin-right: 60px;
        }

        .image1 {
            margin-right: 60px;
        }

        .image3 {
            margin-right: 60px;
        }

        .butn1 {
            width: 80px;
            height: 30px;
            background: #FC6550;
            border: 1px solid #FFF9F6;
            border-radius: 30px;
            margin-right: 30px;
            color: #fff;
            line-height: 30px;
        }

        .butn2 {
            width: 80px;
            height: 30px;
            background-color: #fff;
            border: 1px solid #FC6550;
            border-radius: 30px;
            line-height: 30px;
            color: #FC6550;
        }
    }
}
</style>