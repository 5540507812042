import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import MetaInfo from 'vue-meta-info' //seo优化
import AOS from "aos"
import "../node_modules/aos/dist/aos.css"
import 'animate.css';
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false
Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})
// Vue.use(VueAwesomeSwiper )
Vue.use(ElementUI);
Vue.use(MetaInfo);
Vue.use(AOS)
Vue.prototype.$api
// Vue.use(Api)
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    AOS.init({
      offset: 200,
      duration: 500,//持续时间
      easing: 'ease-in-sine',
      delay: 100
    })
  }
}).$mount('#app')
