<template>
  <div class="nav">
    <img src="../static/img/huileju_logo@2x(1).png" alt="" />
    <ul style="position: relative">
      <li @mouseenter="enterProduct($event, item.id)"
        :class="active != item.id ? 'nav_item' : 'nav_item nav_item_active'" @click="customer(item.id)"
        @mouseleave="leaveProduct" v-for="(item) in navList" :key="item.id" slot="reference">
        {{ item.title }}
        <img class="more" v-show="item.id == 'pr'" :style="{
          transform: `rotate(${deg}deg)`,
          transition: 'all .3s linear',
        }" src="../static/img/more(1).png" alt="" />
      </li>
      <!-- 响应式导航按钮 -->
      <img class="moreMenu" @click="moreMenu" src="../static/img/more(2).png"
        style="width: 50px; position: fixed; right: 10px; cursor: pointer" alt="" />
      <div class="moreMenuList" :style="{ height: menuHeight }">
        <li @mouseenter="enterProduct($event, item.id)" :style="{ display: 'flex', display: moreMenuDisplay }"
          @click="customer(item.id)" v-for="item in navList" :key="item.id">
          {{ item.title }}
          <img class="more" v-show="item.id == 'pr'" :style="{
            transform: `rotate(90deg)`,
            transition: 'all .3s linear',
          }" src="../static/img/products/more_icon.png" alt="" />
        </li>
      </div>
      <div @mouseenter="enterProduct" @mouseleave="leaveProduct" class="more_product" :style="{ height: moreHeight }">
        <ul :style="{ display: moreHeight == '0px' ? 'none' : 'inline-block' }">
          <!-- <div >
            <li @click="goProduct(item.id)" v-for="item in productList" :key="item.id">
              <img :src="item.imgUrl" alt="" />
              <p>{{ item.text1 }}</p>
              <p>{{ item.text2 }}</p>
              <div class="hover-line"></div>
            </li>
          </div> -->
          <productHeader></productHeader>

        </ul>
      </div>
    </ul>
    <div class="mask" :style="{ display: zIndex }"></div>
  </div>
</template>

<script>
import productHeader from "./header/productHeader.vue"
export default {
  components: { productHeader },
  name: "headerNav",
  props: {},
  data() {
    return {
      moreHeight: "0px",
      active: "ho",
      deg: "-90",
      zIndex: "none",
      productList: [
        {
          imgUrl: require("../static/img/products/icon_01@2(2).png"),
          text1: "悠速外卖o2o",
          text2: "平台系统",
          id: "yswmo2o",
        },
        {
          imgUrl: require("../static/img/products/icon_02@2x(1).png"),
          text1: "悠速门店",
          text2: "连锁系统",
          id: "ysmd",
        },
        {
          imgUrl: require("../static/img/products/icon_03@2x(5).png"),
          text1: "悠速外卖",
          text2: "订餐系统",
          id: "yswm",
        },
        {
          imgUrl: require("../static/img/products/icon_04@2x(2).png"),
          text1: "悠速社区",
          text2: "团购系统",
          id: "yssq",
        },
        {
          imgUrl: require("../static/img/products/icon_05@2x(3).png"),
          text1: "悠速同城",
          text2: "信息/跑腿系统",
          id: "ystc",
        },
        {
          imgUrl: require("../static/img/products/icon_06@2x(4).png"),
          text1: "悠速智慧砂石",
          text2: "管理系统",
          id: "zhss",
        },
      ],
      navList: [
        {
          title: "首页",
          id: "ho",
        },
        {
          title: "产品中心",
          id: "pr",
        },
        {
          title: "客户案例",
          id: "ca",
        },
        {
          title: "代理招商",
          id: "ag",
        },
        {
          title: "行业资讯",
          id: "in",
        },
        {
          title: "关于我们",
          id: "aw",
        },
        {
          title: "申请试用",
          id: "ap",
        },
      ],
      menuHeight: "0px",
      moreMenuDisplay: "none",
    };
  },
  computed: {
  },
  inject: ["reload"],
  methods: {

    moreMenu() {
      if (this.menuHeight == "0px") {
        this.menuHeight = this.navList.length * 55 + "px";
        setTimeout(() => {
          this.moreMenuDisplay = "block";
        }, 170);
      } else {
        this.menuHeight = "0px";
        this.moreMenuDisplay = "none";
      }
    },
    enterProduct(e, id) {
      this.active = id;
      if (
        e.target.innerText == "产品中心 " ||
        e.target.className == "more_product"
      ) {
        this.deg = "90";
        this.moreHeight = "300px";
        this.zIndex = "block";
      }
    },
    leaveProduct() {
      this.deg = "-90";
      this.moreHeight = "0px";
      this.zIndex = "none";
    },
    // 产品中心子目录
    goProduct(id) {
      switch (id) {
        case "yswmo2o":
          this.$router.push(`/pr?active="pr"`);
      }
    },
    customer(active) {
      document.documentElement.scrollTop = 0;
      switch (active) {
        case "ho":
          this.$router.push({
            name: "ho",
            params: { active: "ho" },
          });
          break;
        case "pr":
          this.$router.push({path: "/pr", query: { se: "1" }});
          break;
        case "ca":
          this.$router.push('/ca?ac="ca"');
          break;
        case "ag":
          this.$router.push(`/ag?ac="ag"`);
          break;
        case "in":
          this.$router.push(
            `/in?ac="in"`
          );
          break;
        case "aw":
          this.$router.push(`/aw?ac="aw"`);
          break;
        case "ap":
          this.$router.push(`/ap?ac="ap"`);
          break;
        default:
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hover-line {
  width: 30px;
  height: 4px;
  background: #fc634e;
  border-radius: 2px;
  margin-left: 17px;
  margin-top: 17px;
  display: none;
}

.moreMenuList {
  position: fixed;
  width: 100%;
  right: 0;
  top: 70px;
  background: white;
  transition: all 0.3s;

  li {
    cursor: pointer;
    height: 40px;
    margin: 15px;
    color: black;
    flex-direction: column;
    align-items: center;
  }

  li:hover {
    border-bottom: 3px solid black;
  }
}

@media screen and (max-width: 320px) {
  .nav_item {
    cursor: pointer;
    transition: all 0.3s linear;
    display: none;
  }

  .nav_item_active {
    border-bottom: 3px solid white;
    height: 70px;
    line-height: 70px;
    transition: all 0.3s linear;
  }

  header>div:nth-of-type(1)>ul {
    display: flex;
    margin-left: 60px;
    flex: 1;
    color: white;
    justify-content: space-between;
    align-items: center;
  }

  .moreMenu {
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 1150px) {
  .moreMenu {
    display: block;
  }

  .nav_item {
    cursor: pointer;
    width: 100px;
    transition: all 0.3s linear;
    display: none;
  }

  .nav_item_active {
    border-bottom: 3px solid white;
    height: 70px;
    line-height: 70px;
    transition: all 0.3s linear;
  }

  header>div:nth-of-type(1)>ul {
    display: flex;
    margin-left: 60px;
    flex: 1;
    color: white;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1150px) {
  .moreMenu {
    display: none;
  }

  .nav_item {
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
  }

  .nav_item_active {
    border-bottom: 3px solid white;
    height: 70px;
    line-height: 70px;
    transition: all 0.3s linear;
  }

  header>div:nth-of-type(1)>ul {
    display: flex;
    margin-left: 60px;
    flex: 1;
    color: white;
    justify-content: space-between;
    align-items: center;
  }
}

.more {
  width: 10px;
  vertical-align: middle;
}

.more_product {
  width: 100vw;
  left: 0;
  top: 70px;
  // height: 0px;
 background-color: #fff;
 opacity: 0.9;
  position: fixed;
  transition: all 0.3s ease;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.more_product>ul {
  width: 100%;

  >div {
    // display: flex;
    // justify-content: space-between;

    li {
      cursor: pointer;
      height: 118px;
    }

    li:hover .hover-line {
      display: block;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.41;
  background: #000000;
  z-index: -1;
}
</style>
