<template>
    <div style="width: 100%;">
        <el-carousel trigger="click" height="794px" width="1920px">
            <el-carousel-item v-for="item in bannerData.data" :key="item.id">
                <img :src="item.image_url"  height="100%" alt="" @click="experience()">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    name: "Swiper",
    props: ['bannerData' ],
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
        experience() {
            // document.documentElement.scrollTop = 0;
            this.$router.push('/ap')
        },
    },
};
</script>
<style lang="scss" scoped>
.el-carousel__item h3 {
    // line-height: 150px;
    margin: 0;
}
</style>