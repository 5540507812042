import Vue from 'vue'
import Vuex from 'vuex'
import { getBanner, getNews, getNewsDetails } from "@/Api/index.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bannerData: null,
    newsList: null,
    newsDetails: null,
  },
  getters: {
  },
  mutations: {
    getBannerData(state, data) {
      state.bannerData = { ...data }
    },
    getNewsList(state, data) {
      state.newsList = data.data
    },
    getNews(state, data) {
      state.newsDetails = [{ ...data.data }]
    },
  },
  actions: {
    async getIndex({ commit }, val) {
      const { data } = await getBanner(val)
      commit('getBannerData', data)
    },
    async setNewsList({ commit }, pageSize) {
      if (pageSize == undefined) {
        pageSize = 1
      }
      const { data } = await getNews(pageSize)
      commit('getNewsList', data)
    },
    async setNewsDetails({ commit }, num) {
      const { data } = await getNewsDetails(num)
      commit('getNews', data)
    }
  },
  modules: {
  }
})
