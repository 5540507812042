<template>
  <div>
    <div class="consulting_phone">
      <div class="consulting_phone_content" style="width: 1300px;">
        <ul style="width:100% ;">
          <li style="width: 410px;">
            <h3>咨询热线:13693433514 <span style="padding-left: 10px;">13550374154</span></h3>
            <div class="fc">
              <img src="../static/img/footer_consult/phone_icon@2x.png" alt="" />
              <span class="huise">13693433514 </span>
              <span class="huise">13550374154</span>
            </div>
            <div class="fc">
              <img src="../static/img/footer_consult/email_icon@2x.png" alt="" />
              <span class="huise">814382588@qq.com</span>
            </div>
            <div class="fc huise">
              <img src="../static/img/footer_consult/adress_icon@2x.png" alt="" />
              <span>地址：</span>
              <span>四川省成都市高新区天府二街</span>
            </div>
          </li>
          <li style="width: 125px;">
            <h3>官方微信</h3>
            <img class="m11" src="../static/img/footer_consult/wx_code01@2x.png" width="125px" height="125px" alt="" />
          </li>
          <li style="width: 125px;">
            <h3>联系我们</h3>
            <!-- <img class="m11" src="../static/img/footer_consult/wx_code02@2x.png" width="125px" height="125px" alt="" /> -->
            <img class="m11" src="../static/img/footer_consult/wx_code02.png" width="125px" height="125px" alt="" />
            <img class="m35" src="../static/img/footer_consult/wx_code03.png" alt="" width="125px" height="125px" />
          </li>
          <li class="ml35" style="width: 90px;">
            <h3>关于我们</h3>
            <a href="/aw?ac=aw" style="text-decoration-line: none;">
              <p class="m35 mb16">关于惠乐聚</p>
            </a>
            <a href="/aw?ac=aw" style="text-decoration-line: none;">
              <p class="mb16">加入我们</p>
            </a>

          </li>
          <li class="ml35" style="width: 165px; ">
            <h3>相关产品</h3>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16 m35">悠速门店连锁系统</p>
            </a>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16">悠速外卖订餐系统</p>
            </a>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16">悠速社区团购系统</p>
            </a>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16">悠速同城信息系统</p>
            </a>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16">悠速外卖O2O平台系统</p>
            </a>
            <a href="/pr?se=1" style="text-decoration-line: none;">
              <p class="mb16">悠速智慧砂石管理系统</p>
            </a>

          </li>
        </ul>
      </div>
    </div>
    <div style="background: #43464E;color: #fff;width: 100%;font-size: 14px;">
      <p>©2019-2023 成都惠乐聚网络科技有限公司 版权所有 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #FC634D;"><a
            style="color:#FC634D;text-decoration:none" target="_blank" href="https://beian.miit.gov.cn/">ICP证：蜀ICP备19038798号-1</a>
        </span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerInfo",
}
</script>

<style>

</style>