<template>
  <div id="app">
    <!-- 右边咨询选择 -->
    <Chat class="chat"></Chat>
    <!-- 左边在线咨询 -->
    <!-- <leftChat class="leftchat"></leftChat> -->
    <model></model>
    <router-view v-if="isShow"> </router-view>
  </div>
</template>

<script>
import Chat from "@/components/chat.vue";
// import leftChat from "@/components/leftChat.vue";
import model from "@/components/model.vue";
export default {
  components: { Chat, model },
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isShow: true,
    };
  },
  created() {
    //在线咨询爱翻番
    this.liveChat()
  },
  methods: {
    //在线咨询爱翻番
    liveChat() {
      var _hmt = _hmt || [];
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?51f8591fb6d39f1df76beb4cbd3afd3e";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    },
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    chacha() {
      //点击叉掉弹框
      let tankuang_big = document.getElementById("tankuang_big");
      tankuang_big.style.display = "none";
      this.timerr();
    },
    timerr() {
      var timer = setInterval(function () {
        let tankuang_big = document.getElementById("tankuang_big");
        tankuang_big.style.display = "block";
        clearTimeout(timer);
      }, 3000); //每隔30秒弹出弹框
    },
  },
  // mounted: {},
};
</script>

<style lang="scss">
@import url("./static/main.css");



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.tankuang_big {
  position: fixed;
  top: calc(50% - 205px);
  left: calc(50% - 820px);
  z-index: 1;
  margin: 0 auto;
  animation: fadenum 5s 1;
}

@keyframes tankuang_big {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tankuang {
  width: 820px;
  height: 410px;
  display: flex;
  position: relative;
  top: 20px;
  left: 50%;
}

.chachaimg {
  width: 819px;
  height: 409px;
  position: relative;
  width: 100%;
}

.chacha {
  width: 24px;
  position: absolute;
  top: 0;
  right: -10px;
  z-index: 1;
}

.erweima {
  width: 120px;
  height: 121px;
  background: #ff9776;
  border-radius: 10px;
  position: absolute;
  top: 57px;
  right: 54px;
  z-index: 1;

  .bg_white {
    width: 100px;
    height: 90px;
    background-color: white;
    margin: 20px auto;

    >img {
      width: 100px;
      height: 90px;
      border: 5px solid white;
    }
  }
}

.erweima_white {
  width: 50px;
  height: 20px;
  background-color: white;
  position: absolute;
  border-radius: 10px;
  left: 10px;
  top: 10px;
  z-index: -1;
}

.wenzi_erweima {
  position: absolute;
  left: 70px;
  top: 3px;
  color: white;
  font-size: 12px;
}

// 右边咨询选择
.chat {
  background-color: rgb(246, 187, 7);
  position: fixed;
  right: 0px;
  top: 40%;
  z-index: 3;
}

// 左边在线咨询
.leftchat {
  position: fixed;
  left: 0px;
  top: 30%;
  z-index: 3;
}
</style>
