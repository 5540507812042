<template>
  <div>
    <div class="qq">
      <!-- <a href="http://wpa.qq.com/msgrd?v=3&uin=1129917962&site=qq&menu=yes" > -->
      <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2279503099&site=qq&menu=yes">
        <div class="qq-img"></div>
        <p>QQ</p>
      </a>
    </div>
    <div class="qq">
      <div>
        <div class="phone-img"></div>
        <p>咨询</p>
      </div>
      <div class="phone-hover">
        <h2>13693433514</h2>
        <span>15108231446&nbsp;&nbsp;&nbsp;&nbsp;13550374154</span>
      </div>
    </div>
    <div class="qq">
      <div>
        <div class="wx-img"></div>
        <p>微信</p>
      </div>
      <div class="wx-code">
        <div style="display: flex;justify-content: space-around;">
          <!-- <img src="../static/img/footer_consult/wx_code02@2x.png" alt="" width="100px" /> -->
          <img src="../static/img/footer_consult/wx_code02.png" alt="" width="100px" />
          <img src="../static/img/footer_consult/wx_code03.png" alt="" width="100px" />
        </div>
        <p class="text-center">微信扫一扫立即咨询</p>
      </div>
    </div>
    <div class="backtop" @click="backTop()">
      <img src="../assets/backTop1.png" alt="" height="40px" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Chat",
  methods: {
    backTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.text-center {
  padding-top: 10px;
}

.qq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  width: 80px;
  height: 80px;
  line-height: 15px;
  position: relative;
}

.backtop {
  padding-top: 20px;
  width: 80px;
  height: 80px;
}

.qq-img {
  height: 40px;
  width: 40px;
  background-image: url(../assets/QQ1.png);
  background-size: 40px 40px;
  margin: 0px auto;
}

.phone-img {
  height: 40px;
  width: 40px;
  background-image: url(../assets/phone1.png);
  background-size: 40px 40px;
  margin: 0px auto;
}

.wx-img {
  height: 40px;
  width: 40px;
  background-image: url(../assets/wx1.png);
  background-size: 40px 40px;
  margin: 0px auto;
}

.qq:hover .qq-img {
  background-image: url(../assets/QQ2.png);
  transition: 1s;
}

.qq:hover .phone-img {
  background-image: url(../assets/phone2.png);
  transition: 1s;
}

.qq:hover .wx-img {
  background-image: url(../assets/wx2.png);
  transition: 1s;
}

.qq:hover {
  background-color: #fff;
  color: rgb(246, 187, 7);
}

.qq a {
  color: #fff;
  text-decoration: none
}

.qq:hover a {
  color: rgb(246, 187, 7);
}

.phone-hover {
  line-height: 40px;
  position: absolute;
  right: -250px;
  top: 0px;
  background: white;
  box-shadow: 0px 8px 30px 0px rgb(162 162 162 / 20%);
  height: 80px;
  width: 250px;
  padding-left: 20px;
  opacity: 0.5;
}

.qq:hover .phone-hover {
  position: absolute;
  right: 80px;
  display: block;
  opacity: 1;
  transition: 1s;
}

.qq:hover .phone-hover span {
  color: #888;
  font-size: 12px;
}

.wx-code {
  padding-top: 20px;
  position: absolute;
  right: -220px;
  /* right: 100px; */
  top: -50px;
  /* top: 79px; */
  background: white;
  box-shadow: 0px 8px 30px 0px rgb(162 162 162 / 20%);
  height: 170px;
  width: 220px;
  /* opacity: 0.5; */
}

.qq:hover .wx-code {
  color: black;
  position: absolute;
  right: 80px;
  display: block;
  opacity: 1;
  transition: 1s;
}
</style>