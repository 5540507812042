<template>
  <div class="main" style="position: relative;">
    <!-- <div v-title data-title="成都惠乐聚网络科技有限公司-校园外卖/跑腿SAAS软件系统研发企业-官网"></div> -->
    <div class="baner">
      <header>
        <header-nav ref="navData"></header-nav>
      </header>
      <!-- <img src="../static/img/index_banner.png" alt="" height="790px" /> -->
      <Swiper :bannerData="bannerData"></Swiper>
    </div>
    <!-- 解决方案 -->
    <div class="plan">
      <div class="plan_content">
        <div
          style="position: absolute;top: 657px;left: 0; z-index: 2; width: 100%;background-color: rgba(255,255,255,0.84);">
          <div class="plan_content_header">
            <div data-aos="fade-up">
              <img src="../static/img/team_icon@2x.png" alt="" />
              <p>100人+</p>
              <p>技术团队</p>
              <span></span>
            </div>
            <div data-aos="fade-up">
              <img src="../static/img/experience_icon@2x.png" alt="" />
              <p>10年+</p>
              <p>软件开发经验</p>
              <span></span>
            </div>
            <div data-aos="fade-up">
              <img src="../static/img/successful_icon@2x.png" alt="" />
              <p>3000+</p>
              <p>成功案例</p>
              <span></span>
            </div>
            <div data-aos="fade-up">
              <img src="../static/img/customer_service_icon@2x.png" alt="" />
              <p>365天*24h</p>
              <p>售后支持</p>
            </div>
          </div>
        </div>
        <!-- <div class="plan_title">
          <h1>解决方案</h1>
          <p>深耕细分行业，提供更加垂直的解决方案</p>
        </div>
        <ul class="plan_list">
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/information_icon@2x.png" alt="" />
            <span>餐饮外卖服务</span>
          </li>
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/stores_icon@2x.png" alt="" />
            <span>门店连锁服务</span>
          </li>
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/school_icon@2x.png" alt="" />
            <span>校园生活服务</span>
          </li>
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/com_city_icon@2x.png" alt="" />
            <span>同城生活服务</span>
          </li>
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/group_buy_icon@2x.png" alt="" />
            <span>社区团购服务</span>
          </li>
          <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <img src="../static/img/wisdom_icon@2x.png" alt="" />
            <span>智慧砂石服务</span>
          </li>
        </ul> -->
      </div>
    </div>
    <!-- 系统 -->
    <System />
    <!-- 产品展示 -->
    <div class="product">
      <div data-aos="flip-left" class="product_content">
        <div class="product_content_title">
          <p></p>
          <p>惠乐聚致力于为快速构建本地生活服务系统提供专业的解决方案</p>
        </div>
        <div class="product_content_swiper" @mouseenter="swiperEnter" @mouseleave="swiperOut">
          <ul class="product_content_swiper_left">
            <li @click="onSwiper(item.id)" style="cursor: pointer" v-for="item in productList" :key="item.id"
              :class="item.id == productActive ? 'active' : ''">
              <p>{{ item.title1 }}</p>
              <p>{{ item.title2 }}</p>
              <span v-show="item.id == productActive"></span>
            </li>
          </ul>
          <div class="product_content_swiper_right" style="position: relative;">
            <img v-for="(item, index) in swiperRightImgList" v-show="item.id == productActive"
              :class="item.id == productActive ? 'swiper_right_Active swiper_right' : 'swiper_right'" :key="index"
              style="height: 100%;" :src="item.imgUrl" alt="" />
            <div v-show="productActive == item.id" class="product_content_swiper_right_text"
              v-for="item in productTitle" :key="item.id">
              <p style="font-size: 24px;margin-top: 5px; font-weight: bold">
                {{ item.title1 }}
              </p>
              <p style="font-size: 12px; font-weight: 400; margin-top: 5px">
                {{ item.title2 }}
              </p>
              <p style="font-size: 14px; font-weight: 400; margin-top: 30px">
                {{ item.title3 }}
              </p>
              <div>
                <button style="background: linear-gradient(90deg,#ffa17d 0%, #fc624d 100%);"
                  @click="goDetailsPage(item.id)">
                  查看详情
                </button>
                <button style="
                    background: rgba(255, 255, 255, 0);
                    border: 1px solid #ffffff;
                  " @click="freeTry()">
                  免费试用
                </button>
              </div>
            </div>
            <div class="small_mask" style="">

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service_scenario">
      <div class="service_scenario_title">
        <h1>服务场景</h1>
        <p>SERVICE SCENARIO</p>
        <p></p>
        <p>深耕细分行业，提供更加垂直的行业解决方案</p>
        <button @click="experience()">立即体验</button>
      </div>
      <ul class="service_scenario_list">
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/take_out_icon@2x.png" alt="" />
          <p>餐饮美食</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/supermarkect_icon@2x.png" alt="" />
          <p>便利商超</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/hotel_reservation_icon@2x.png" alt="" />
          <p>酒店住宿</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/foods_icon@2x.png" alt="" />
          <p>外卖订餐</p>
        </li>
      </ul>
      <ul class="service_scenario_list">
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/business_icon@2x.png" alt="" />
          <p>电商零售</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/moveHome_icon@2x.png" alt="" />
          <p>搬家货运</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/goodCars_icon@2x.png" alt="" />
          <p>同城代驾</p>
        </li>
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="300">
          <img class="scenario" src="../static/img/education_icon@2x.png" alt="" />
          <p>信息发布</p>
        </li>
      </ul>
    </div>
    <div class="cause">
      <div class="cause_title">
        <h1>做系统为什么选择悠速</h1>
        <p>(追求专业服务,成就品牌价值)</p>
      </div>
      <ul class="cause_list">
        <li data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" v-for="item in cause_list" :key="item.title1">
          <img :src="item.imgUrl" alt="" />
          <h3>{{ item.title1 }}</h3>
          <span class="cause_list_active"></span>
          <p>
            {{ item.title2 }}
          </p>
        </li>
      </ul>
    </div>
    <!-- 行业资讯 -->
    <div class="customer_case">
      <div class="customer_case_content">
        <div class="customer_case_left">
          <img src="../static/img/home/case@2x.png" alt="" />
          <p>
            专注校园外卖市场,提供外卖+跑腿+代购等多元化应用场景,打造智慧校园生活服务平台.
          </p>
          <button @click="experience()">申请使用</button>
        </div>
        <div class="customer_case_center_box " :style="newsList.data.length > 4 ? scrollBar : ''">
          <div class="customer_case_center" v-for=" item in newsList.data" :key="item.id" @click="goDetails(item.id)"
            style="cursor:pointer ;">
            <div class="customer_case_img">
              <img data-aos="zoom-in-right" width="300px" height="180px" :src="item.image_url" alt="" />
              <!-- <p>川大校区外卖</p> -->
            </div>
            <p class="school_title" style="margin:20px 0 10px 0;">{{ item.title.length > 15 ? item.title.slice(0, 15)
    + '...' : item.title
}}</p>
            <p style="text-align:left ;">{{ item.no_tag.length > 30 ? item.no_tag.slice(0, 30) + '...' : item.no_tag }}
            </p>
            <span class="left_line"></span>
          </div>
        </div>
        <!-- <div class="customer_case_center_box">
          <div class="customer_case_right">
            <p class="school_title">校园外卖案例</p>
            <span>专注校园外卖市场,提供外卖+跑腿+代购等多元化应用场景,打造智慧校园生活服务平台.</span>
            <span class="left_line"></span>
            <div class="customer_case_img">
              <img data-aos="zoom-in-left" src="../static/img/customerCase/take-out15@2x.png" alt="" />
              <p>川大校区外卖</p>
            </div>
            <div class="customer_case_img">
              <img data-aos="zoom-in-left" src="../static/img/customerCase/take-out16@2x.png" alt="" />
              <p>叮当校园外卖</p>
            </div>
          </div>
        </div> -->
      </div>
      <div style="position: absolute;bottom: 20px;" v-if="newsList.data.length > 4">
        <el-pagination @current-change="changePageNum" background layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <!-- <div class="partners">
      <div class="partners_content">
        <img src="../static/img/text_pabtnr@2x.png" alt="" />
        <div class="swiper">
          <div class="swiper_first">
            <ul>
              <li><img src="../static/img/hezuo/tencent_cloud@2x.png" alt="" /></li>
              <li><img src="../static/img/hezuo/weixin.png" alt="" /></li>
              <li><img src="../static/img/hezuo/ali.png" alt="" /></li>
              <li><img src="../static/img/hezuo/baidu.png" alt="" /></li>
              <li><img src="../static/img/hezuo/huawei1@2x.png" alt="" /></li>
              <li><img src="../static/img/hezuo/zijie.png" alt="" /></li>
              <li><img src="../static/img/hezuo/tencent_cloud@2x.png" alt="" /></li>
              <li><img src="../static/img/hezuo/weixin.png" alt="" /></li>
              <li><img src="../static/img/hezuo/ali.png" alt="" /></li>
              <li><img src="../static/img/hezuo/baidu.png" alt="" /></li>
            </ul>
          </div>
          <div class="swiper_second">
            <ul>
              <li><img src="../static/img/hezuo/yingyb.png" alt="" /></li>
              <li><img src="../static/img/hezuo/eleme.png" alt="" /></li>
              <li><img src="../static/img/hezuo/meituan.png" alt="" /></li>
              <li><img src="../static/img/hezuo/zhifubao.png" alt="" /></li>
              <li><img src="../static/img/hezuo/dada.png" alt="" /></li>
              <li><img src="../static/img/hezuo/zgdx.png" alt="" /></li>
              <li><img src="../static/img/hezuo/shufeng.png" alt="" /></li>
              <li><img src="../static/img/hezuo/yingyb.png" alt="" /></li>
              <li><img src="../static/img/hezuo/eleme.png" alt="" /></li>
              <li><img src="../static/img/hezuo/meituan.png" alt="" /></li>
              <li><img src="../static/img/hezuo/zhifubao.png" alt="" /></li>
              <li><img src="../static/img/hezuo/dada.png" alt="" /></li>
            </ul>
          </div>
          <div class="swiper_third">
            <ul>
              <li><img src="../static/img/hezuo/zgyd.png" alt="" /></li>
              <li><img src="../static/img/hezuo/fengniao@2x.png" alt="" /></li>
              <li><img src="../static/img/hezuo/dianwo.png" alt="" /></li>
              <li><img src="../static/img/hezuo/shentong.png" alt="" /></li>
              <li><img src="../static/img/hezuo/uu.png" alt="" /></li>
              <li><img src="../static/img/hezuo/ali.png" alt="" /></li>
              <li><img src="../static/img/hezuo/zgyd.png" alt="" /></li>
              <li><img src="../static/img/hezuo/fengniao@2x.png" alt="" /></li>
              <li><img src="../static/img/hezuo/dianwo.png" alt="" /></li>
              <li><img src="../static/img/hezuo/shentong.png" alt="" /></li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 我们的资质与实力 -->
    <div class="power" style="padding-bottom: 50px;">
      <h1>我们的资质与实力</h1>
      <span class="active"></span>
      <p>缜密逻辑体系，打造领先行业的标杆产品</p>
      <div>
        <ul ref="ululul" style="display: flex">
          <li v-for="item in certificate" :key="item">
            <img style="height: 220px" :src="item" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/1.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/2.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/3.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/4.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/5.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/6.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/7.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/8.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
          <li>
            <img style="height: 220px"
              src="https://image.youqucms.com/huileju-img/huileju/9.png?imageMogr2/thumbnail/!20p" alt="" />
          </li>
        </ul>
      </div>
      <div class="butn">
        <button @click="experience()">申请试用</button>
        <button @click="experience()">立即咨询</button>
      </div>
    </div>
    <footer>
      <FooterOrange :scrollHeight="scrollHeight"></FooterOrange>
      <footer-info></footer-info>
    </footer>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerInfo from "@/components/footerInfo.vue";
import FooterInfo from "@/components/footerInfo.vue";
import FooterOrange from "@/components/footerOrange.vue";
import System from "@/components/home/system.vue"
import Swiper from "@/components/home/swiper.vue"
import { mapActions } from 'vuex';
export default {
  components: { headerNav, footerInfo, FooterInfo, FooterOrange, System, Swiper },
  data() {
    return {
      certificate: [],
      scrollHeight: 4400,
      bannerData: [],
      newsList: {
        data: []
      },
      scrollBar: {
        overflowY: 'scroll',
      },
      productActive: "yswmo2o",
      productTitle: [
        {
          title1: "悠速外卖O2O平台系统",
          title2:
            "集外卖、配送、营销、管理于一体,无缝对接线上线下,助力商家玩转互联网+商业新生态",
          title3:
            " 首页DIY布局、多商户入驻、广告商入驻、多站点加盟、服务商分账、商家独立后台、用户评论、多样化营销、更多...",
          id: "yswmo2o",
        },
        // {
        //   title1: "悠速连锁门店",
        //   title2:
        //     "集门店管理+会员管理+营销于一体,切合核心业务场景,将门店业务环节全面覆盖,提升销量",
        //   title3:
        //     "门店管理、会员储值、积分商城、商品管理、统一营销、小程序直播、财务统计、销量统计、更多...",
        //   id: "ysmd",
        // },
        // {
        //   title1: "悠速外卖订餐系统",
        //   title2:
        //     "包含扫码点餐、堂食、订座、外卖、自取等功能,提供外卖点餐一站式服务,让每一个商家都有自己的外卖平台",
        //   title3:
        //     "扫码点餐、堂食/订座、外卖/自取、排队叫号、酒水寄存、当面付、收银台、小票打印、更多...",
        //   id: "yswm",
        // },
        // {
        //   title1: "悠速社区团购系统",
        //   title2:
        //     "团购+新零售电商+直播卖货+多级分销模式,助力商家整合多个社区社群资源,让电商新零售更简单",
        //   title3:
        //     "多人拼团、限时秒杀、砍价、群接龙、多级分销、直播售卖、积分商城、社区论坛、更多...",
        //   id: "yssq",
        // },
        {
          title1: "悠速跑腿系统",
          title2:
            "依托移动互联网平台，借助互联网技术、云计算、传感器技术和RFID射频识别技术充分实现互联网在资源配置过程中的优化和集成作用……",
          title3:
            "智能监控系统、卡口抓拍系统、河道采砂智能监管系统、砂石销售管理系统、石料厂管理系统、综合管理云平台、大数据中心平台更多…",
          id: "yssq",
        },
        // {
        //   title1: "悠速智慧砂石管理系统",
        //   title2:
        //     "依托移动互联网平台，借助互联网技术、云计算、传感器技术和RFID射频识别技术充分实现互联网在资源配置过程中的优化和集成作用……",
        //   title3:
        //     "智能监控系统、卡口抓拍系统、河道采砂智能监管系统、砂石销售管理系统、石料厂管理系统、综合管理云平台、大数据中心平台更多…",
        //   id: "zhss",
        // },
      ],
      swiperRightImgList: [
        {
          imgUrl: "https://image.youqucms.com/huileju-img/img/products/O2O_banner%402x.png?imageMogr2/format/jpg",
          id: "yswmo2o",
        },
        // {
        //   imgUrl: require("../static/img/products/chain_stores_banner.png"),
        //   id: "ysmd",
        // },
        // {
        //   imgUrl: require("../static/img/products/yousu_bannner.png"),
        //   id: "yswm",
        // },
        {
          imgUrl: "https://image.youqucms.com/huileju-img/img/products/yousu_system_banner.png?imageMogr2/format/jpg",
          id: "yssq",
        },
        //         {
        //   imgUrl: require("../static/img/products/yousu_system_banner.png"),
        //   id: "ystc",
        // },
        // {
        //   imgUrl: require("../static/img/products/yousu_system_banner1.png"),
        //   id: "zhss",
        // },
      ],
      cause_list: [
        {
          imgUrl: require("../static/img/teams@2x(1).png"),
          title1: "专业技术团队",
          title2:
            "实体公司专业团队,1对1服务为您的项目保价护航,全程汇报高标准按时间交付。",
        },
        {
          imgUrl: require("../static/img/professional_icon@2x(2).png"),
          title1: "软件开发经验丰富",
          title2:
            "专业技术团队,工作经验10年+,并曾在国内一线互联网公司任职,拥有丰富的互联网开发经验。",
        },
        {
          imgUrl: require("../static/img/price_icon@2x(3).png"),
          title1: "1v1专属售后服务",
          title2:
            "合作是开始，口碑是未来。源码交付售后保障BUG修复，系统高频迭代更新，为客户持续创造价值。",
          id: "",
        },
        {
          imgUrl: require("../static/img/custom_icon@2x(4).png"),
          title1: "支持定制开发",
          title2:
            "按需定制开发，从产品逻辑、UI设计、技术开发、运营测试再到运营维护，一条龙服务。",
        },
        {
          imgUrl: require("../static/img/power_icon@2x(5).png"),
          title1: "正规实力企业",
          title2:
            "双软&高新技术企业，具有资深的软件开发工程师和完成的技术团队，为您的项目实力护航。",
        },
        {
          imgUrl: require("../static/img/exclusive_icon@2x(6).png"),
          title1: "价格统一透明",
          title2:
            "正规企业提供标准化服务，全套产品价格体系公开透明，签约合作保障双方权益，绝不(乱)收一分钱。",
        },
      ],
      productList: [
        {
          title1: "悠速外卖o2o",
          title2: "平台系统",
          id: "yswmo2o",
        },
        // {
        //   title1: "悠速门店",
        //   title2: "连锁系统",
        //   id: "ysmd",
        // },
        // {
        //   title1: "悠速外卖",
        //   title2: "订餐系统",
        //   id: "yswm",
        // },
        {
          title1: "悠速",
          title2: "跑腿系统",
          id: "yssq",
        },
        // {
        //   title1: "悠速同城",
        //   title2: "信息系统",
        //   id: "ystc",
        // },
        // {
        //   title1: "悠速智慧砂石",
        //   title2: "管理系统",
        //   id: "zhss",
        // },
      ],
      swiperTimer: null,
    };
  },
  metaInfo: {
    title: "惠乐聚科技-悠速外卖配送系统源码|校园外卖跑腿配送|软件服务商", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "外卖系统源码,外卖小程序,跑腿小程序,跑腿系统开发,外卖源码二开",
      },
      {
        name: "description",
        content: "惠乐聚科技为外卖软件平台创业者提供一站式O2O外卖系统，是互联网外卖行业具有代表性的服务商,具备为各类企业用户提供信息化产品和解决方案的能力。旗下悠速外卖系统专做同城生活系统、校园外卖跑腿系统、多平台运营系统专为跑腿团队、外卖商家、跑腿公司、本地创业者、同城外卖配送服务商、校园外卖跑服团队、快递公司提供全套的的腿技术支持和外卖O2O平台。",
      },
    ],
  },
  computed: {
    total() {
      return this.newsList.total
    },
    pageSize() {
      return this.newsList.data.to
    }
  },
  mounted() {
    this.certificateNum();
    this.$refs.navData.$data.active = this.$route.name;
    this.swiperTimer = setInterval(() => {
      this.swiperTime();
    }, 1500);
  },
  created() {
    this.getBannerData()
    this.getNewsList()
  },
  methods: {
    ...mapActions(['setNewsList', 'getIndex', 'setNewsDetails']),
    certificateNum() {
      for (let i = 1; i <= 25; i++) {
        this.certificate.push(`https://image.youqucms.com/huileju-img/huileju/${i}.png?imageMogr2/thumbnail/!20p`);
      }
    },
    goDetailsPage(id) {
      document.documentElement.scrollTop = 0;
      if (id == 'yswmo2o') {
        this.$router.push({ path: '/pr', query: { se: 1, } })
      } else if (id == 'yssq') {
        this.$router.push({ path: '/pr', query: { se: 2, } })
      }
    },
    goDetails(id) {
      this.setNewsDetails(id)
      this.$router.push('/inDt')
    },
    getBannerData() {
      this.getIndex({ type: 1 }).then(() => {
        this.bannerData = this.$store.state.bannerData
      })

    },
    getNewsList(val = 1) {
      this.setNewsList(val).then(() => {
        this.newsList = this.$store.state.newsList
      })

    },
    experience() {
      document.documentElement.scrollTop = 0;
      this.$router.push('/ap')
    },
    changePageNum(val) {
      this.getNewsList(val)
      // this.newsData=this.newsList.slice(0,1)
    },
    onSwiper(id) {
      this.productActive = id;
    },
    swiperTime() {
      let id = this.swiperRightImgList.findIndex(
        (item) => item.id == this.productActive
      );
      if (id >= this.swiperRightImgList.length - 1) {
        id = -1;
      }
      this.productActive = this.swiperRightImgList[id + 1].id;
    },
    swiperEnter() {
      clearInterval(this.swiperTimer);
    },
    swiperOut() {
      this.swiperTimer = setInterval(() => {
        this.swiperTime();
      }, 1500);
    },
    freeTry() {
      document.documentElement.scrollTop = 0;
      this.$router.push(`/ap?active="ap"`);
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../static/home.css");

.service_scenario_list {
  li:hover {
    transform: scale(1.1);
  }
}

.swiper_right {
  opacity: 0;
}

.swiper_right_Active {
  opacity: 1;
}

.product_content_swiper_right_text {
  position: absolute;
  top: 480px;
  left: 30px;
  text-align: left;
  width: 512px;
  color: #ffffff;
  z-index: 10;

  >div {
    position: absolute;
    right: -380px;
    bottom: -10px;
  }

  >p {
    width: 400px;
  }

  button {
    outline: none;
    border: none;
    width: 134px;
    height: 44px;
    border-radius: 22px;
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
}

.active {
  background: #f96d16;
  border-radius: 10px;
  color: white;
}

.customer_case_img {
  margin-top: 30px;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
}

.cause_list_active {
  width: 100%;
  background: linear-gradient(90deg, #ffa17d 0%, #fc624d 100%);
  position: absolute;
  top: 0;
  height: 0px;
  transition: all 0.3s;
  z-index: -1;
}

.cause_list>li {
  &:hover>.cause_list_active {
    height: 255px;
  }

  &:hover {
    color: white;
  }

  &:hover>p {
    color: white;
  }
}

.scenario {
  transition: all 0.3 linear;
}

.scenario:hover {
  box-shadow: 0 0 29px 13px #fff6f6;

}

.small_mask {
  width: 100%;
  height: 174px;
  background: #333333;
  opacity: 0.39;
  border-radius: 0px 0px 10px 10px;
  position: absolute;
  bottom: 0px;
  padding-top: 20px;
}

.power {
  padding-top: 71px;

  .active {
    display: inline-block;
    width: 42px;
    height: 8px;
    background: linear-gradient(90deg, #ffa17d 0%, #fc624d 100%);
    margin-top: 10px;
  }

  p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 300;
    color: #666666;
    letter-spacing: 8px;
    margin-top: 25px;
  }

  div {
    width: 100%;
    margin-top: 30px;
    overflow: hidden;

    ul {
      animation: run 33s linear infinite;
      height: 100%;

      li {
        margin-left: 40px;
        padding: 10px;
        box-shadow: 0px 0px 48px 2px rgba(129, 129, 129, 0.13);
        border-radius: 20px;
      }
    }
  }

  .butn {
    padding-top: 70px;

    button {
      width: 160px;
      height: 40px;
      border-radius: 64px;
    }

    button:nth-of-type(1) {
      color: white;
      background: #fd725a;

      border: 1px solid #fd725a;
    }

    // button:nth-of-type(1):hover {
    //   color: white;
    //   background: #fd725a;
    // }

    button:nth-of-type(2) {
      border: 1px solid #fd725a;
      color: #fd725a;
      background-color: #fff;
      margin-left: 30px;
    }

    // button:nth-of-type(2):hover {
    //   color: white;
    //   background: #333333;
    // }
  }
}
</style>