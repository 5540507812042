import Vue from 'vue'
import VueRouter from 'vue-router'
import ho from '../views/HomeView.vue'

Vue.use(VueRouter)
// 4
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'ho',
    component: ho
  },
  // 首页
  {
    path: '/ho',
    name: 'ho',
    component: ho

  },
  //产品中心
  {
    path: '/productcenter',
    name: 'productcenter',
    component: () => import('../views/productcenter.vue')
  },
  {
    path: '/pr',
    name: 'pr',
    component: () => import('../views/cpzx.vue')
  },
  // 客户案例
  {
    path: '/ca',
    name: 'customer',
    component: () => import('../views/customer.vue')
  },
  // 招商代理
  {
    path: '/ag',
    name: 'ag',
    component: () => import('../views/agentInvestment.vue')
  },
  // 行业资讯
  {
    path: '/in',
    name: 'in',
    component: () => import('../views/industryInformation.vue')
  },
  // 关于我们
  {
    path: '/aw',
    name: 'aw',
    component: () => import('../views/aboutWe.vue')
  },
  // 行业详情
  {
    path: '/inDt',
    name: 'inDt',
    component: () => import('../views/newsDetails.vue')
  },
  // 申请试用
  {
    path: '/ap',
    name: 'ap',
    component: () => import('../views/applyTry.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
