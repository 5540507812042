<template>
    <div class="content">
        <div class="container">
            <div class="containt" v-for="(item, index) in titleData" :key="index"
                @click="jumpHome($event.target.innerText)">
                <h3 class="title">{{ item.title }}</h3>
                <div class="hover_headings" style="  cursor: pointer;">
                    <p class="headings">{{ item.title1 }}</p>
                </div>
                <div class="hover_headings" style="  cursor: pointer;">
                    <p class="headings">{{ item.title2 }}</p>
                </div>
                <div class="hover_headings" style="  cursor: pointer;">
                    <p class="headings" style="padding-bottom: 15px;">{{ item.title3 }}</p>
                </div>
                <div class="hover_headings" style="  cursor: pointer;">
                    <p class="headings" style="padding-bottom: 15px;">{{ item.title4 }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "productHeader",
    data() {
        return {
            se: 2,
            st: true,
            titleData: [
                {
                    title: '校园',
                    title1: '校园外卖系统',
                    title2: '校园跑腿配送系统',
                },
                {
                    title: '同城',
                    title1: '本地跑腿系统',
                    title2: '同城外卖系统',
                    title3: '同城生活服务系统',
                },
                // {
                //     title: '智慧',
                //     title1: '智慧砂石系统',
                // },
                {
                    title: '服务',
                    title1: 'SaaS租用服务',
                    title2: '独立部署服务',
                    title3: '源码交付服务',
                    title4: '特殊定制服务',
                },
                {
                    title: '下载',
                    title1: '用户端体验',
                    title2: '商家端APP',
                    title3: '骑手端APP',
                    title4: '后台PC端',
                },
            ]
        };
    },
    methods: {
        jumpHome(value) {
            switch (value) {
                case '本地跑腿系统':
                    this.st = !this.st;
                    localStorage.setItem('to', 'systemName0');
                    this.$router.push({ path: '/ho', query: { to: '0', st: this.st } })
                    break;
                case '同城外卖系统':
                    this.st = !this.st;
                    localStorage.setItem('to', 'systemName0');
                    this.$router.push({ path: '/ho', query: { to: '1', st: this.st } })
                    break;
                case '同城生活服务系统':
                    this.st = !this.st;
                    localStorage.setItem('to', 'systemName0');
                    this.$router.push({ path: '/ho', query: { to: '2', st: this.st } })
                    break;
                case '校园跑腿配送系统':
                    document.documentElement.scrollTop = 0;
                    this.st = !this.st;
                    this.se = 2
                    localStorage.setItem('to', 'systemName0');
                    this.$router.push({ path: '/pr', query: { se: this.se, st: this.st, to: '2', } })
                    break;
                case '校园外卖系统':
                    document.documentElement.scrollTop = 0;
                    this.se = 1
                    localStorage.setItem('to', 'systemName0');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { se: this.se, st: this.st, to: '2', } })
                    break;
                case 'SaaS租用服务':
                    localStorage.setItem('to', 'cooperat1');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { se: this.se, to: '3', st: this.st } })
                    break;
                case '特殊定制服务':
                    localStorage.setItem('to', 'customPath');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { to: '4', st: this.st } })
                    break;
                case '用户端体验':
                    localStorage.setItem('to', 'downLoad1');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { se: this.se, to: '5', st: this.st } })
                    break;
                case '商家端APP':
                    localStorage.setItem('to', 'downLoad1');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { se: this.se, to: '5', st: this.st } })
                    break;
                case '骑手端APP':
                    localStorage.setItem('to', 'downLoad1');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { se: this.se, to: '5', st: this.st } })
                    break;
                case '后台PC端':
                    localStorage.setItem('to', 'downLoad1');
                    this.st = !this.st;
                    this.$router.push({ path: '/pr', query: { to: '5', st: this.st } })
                    break;

                default:
                    break;
            }

        },
    }
};
</script>
  
<style lang="scss" scoped>
.content {
    color: #000;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .container {
        width: 60%;
        display: flex;
        justify-content: center;

        .containt {
            width: 200px;
            // height: 230px;
            border-left: 1px solid #CCCCCC;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                padding-bottom: 20px;
                color: #000;
            }

            // .headings {}

            .hover_headings {
                margin-bottom: 15px;
                color: #666666;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                width: 150px;
                height: 40px;
                line-height: 40px;
            }

            .hover_headings:hover {
                background-color: #F3F3F3;
                color: #000000;
                font-weight: bold;
            }
        }
    }
}
</style>