<template>
  <div class="consulting" :class="positionState ? 'consulting1' : 'consulting'">
    <div class="consulting_content">
      <img src="../static/img/horn_icon@2x.png" alt="" height="40px" />
      <!-- <p style="margin-left: 30px; width: 520px">
            现在咨询，了解产品最新报价~
          </p> -->
      <img style="margin-left: 50px;"  src="../static/img/footer_consult/text@2x.png" alt="" height="30px" />
      <!-- <input style="outline: none;"  type="text" placeholder="请输入手机号" name="" id="" v-model="value" />
      <button @click="apply()">申请试用</button> -->
      <div class="inpt">
        <el-select v-model="value" placeholder="意向系统">
          <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="line"></div>
        <div class="inputText">
          <el-input v-model="input" placeholder="联系电话"></el-input>
        </div>
        <button class="butn" @click="experience()">申请试用</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: ['scrollHeight'],
  data() {
    return {
      value: "",
      options: [{
        value: '校园跑腿系统',
        label: '校园跑腿系统'
      }, {
        value: '校园外卖系统',
        label: '校园外卖系统'
      }, {
        value: '智慧砂石系统',
        label: '智慧砂石系统'
      }],
      // value: '',
      input: '',
      currentScroll: 0,
      positionState: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollbox, true)
  },
  methods: {
    handleScrollbox() {
      this.currentScroll = window.pageYOffset //表示当前滚动的位置
      if (this.currentScroll >= this.scrollHeight) {
        //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.positionState = false
      }else{
        this.positionState = true
      }
    },
    experience() {
      document.documentElement.scrollTop = 0;
      this.$router.push({ path: '/ap', query: { type: this.value, phone: this.input } })
    }
  },
};
</script>

<style lang="scss" scoped>
.consulting1 {
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #FFA17D 0%, #FC624D 100%);
  display: flex;
  justify-content: center;
  margin-top: 30px;
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.inpt {
  width: 600px;
  height: 40px;
  background: rgba($color: #fff, $alpha: 0.2);
  border-radius: 30px;
  // margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 10px 0px 10px;
  border: 1px solid #FFFFFF;
  margin-left: 50px;

  .line {
    height: 20px;
    width: 2px;
    background: #fff;
  }

  select {
    width: 100px;
    height: 40px;
    line-height: 40px;
    border: none;
  }
}

::v-deep .el-input__inner {
  width: 160px;
  border: none;
  box-shadow: none;
  background: transparent;
  font-size: 17px;
  color: #fff;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #fff;
}

::v-deep input::placeholder {
  color: #fff;
}

::v-deep .el-input {
  width: 160px;
  background: transparent;
  color: #fff;
  font-size: 17px;
}

::v-deep .inputText .el-input__inner {
  width: 150px;
  background: transparent;
  color: #fff;
  font-size: 17px;
}

::v-deep .inputText .el-input {
  width: 150px;
  background: transparent;
  color: #fff;
  font-size: 18px;
}

.butn {
  width: 150px;
  height: 40px;
  background: #fff;
  border-radius: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FD6B54;
  font-size: 18px;
  font-style: italic;
  border: none;
}
</style>