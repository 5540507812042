import api from "../utils/axiosUtils";
// 提交填写资料
export const postUserInfo = (data) => api({
    url: '/api/customer', // 请求地址
    method: 'post', // 请求方式
    data
})
//获取轮播图banner
export const getBanner = (params) => {
    return api({
        url: '/api/banner',
        method: 'GET',
        params,
    })
}
//获取资讯列表
export const getNews = (pageSize) => {
    return api({
        url: `/api/news?page=${pageSize}`,
        method: 'GET',
        // params: pageSize,
    })
}
//获取资讯详情
export const getNewsDetails = (num) => {
    return api({
        url: `api/news/${num}`,
        method: 'GET',
    })
}