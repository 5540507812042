<template>
    <div class="model" v-if="showState">
        <div class="close" @click="close()"><i class="el-icon-close"></i></div>
        <div class="model1">
            <button class="butn" @click="tryBtn()">申请试用</button>
        </div>
        <div class="inpt">
            <el-select v-model="value" placeholder="意向系统">
                <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="line"></div>
            <div class="inputText">
                <el-input v-model="input" placeholder="联系电话"></el-input>
            </div>
            <button class="butn" @click="experience()">立即体验</button>
        </div>
    </div>
</template>
<script>
export default {
    name: "model",
    data() {
        return {
            options: [{
                value: '校园跑腿系统',
                label: '校园跑腿系统'
            }, {
                value: '校园外卖系统',
                label: '校园外卖系统'
            }, {
                value: '智慧砂石系统',
                label: '智慧砂石系统'
            }],
            value: '',
            input: '',
            showState: true,
        }
    },
    methods: {
        close() {
            this.showState = false;
        },
        tryBtn() {
            this.$router.push({ path: '/ap' })
            this.showState = false;
        },
        experience() {
            this.$router.push({ path: '/ap', query: { type: this.value, phone: this.input } })
            this.showState = false;
        }
    },
}
</script>
<style lang="scss" scoped>
.close {
    font-size: 30px;
    position: absolute;
    right: -35px;
    top: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #000;
    opacity: 0.5;
    cursor: pointer;
    transition: All 1s ease-in-out;
    -webkit-transition: All 1s ease-in-out;
    -moz-transition: All 1s ease-in-out;
    -o-transition: All 1s ease-in-out;

    &:hover {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
    }

}



.el-carousel__item h3 {
    // line-height: 150px;
    margin: 0;
}

::v-deep .el-input__inner {
    width: 130px;
    border: none;
    box-shadow: none;
}

::v-deep .el-input {
    width: 130px;
}

::v-deep .inputText .el-input__inner {
    width: 100px;
}

::v-deep .inputText .el-input {
    width: 100px;
}

.model {
    width: 400px;
    height: 247px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -123px;
    z-index: 333;
    box-shadow: 2px 2px 7px 5px #f5f5f5;
    background-color: #fff;
    border-radius: 10px;
}

.model1 {
    width: 400px;
    height: 200px;
    background-image: url('../static/img/product_center/huileju.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    .butn {
        margin-top: 150px;
        margin-right: 250px;
    }
}

.inpt {
    width: 100%;
    // height: 50px;
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border: 1px solid rgb(231, 230, 230);

    .line {
        height: 20px;
        width: 1px;
        background: #ccc;
    }

    select {
        width: 100px;
        height: 40px;
        line-height: 40px;
        border: none;
    }
}



.butn {
    width: 100px;
    height: 35px;
    background: linear-gradient(90deg, #FFA17D 0%, #FC624D 100%);
    border-radius: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    font-style: italic;
    border: none;
}
</style>